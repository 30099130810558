<template>
  <v-dialog v-model="dialogConfirm" persistent max-width="600">
    <v-card class="pt-5">
      <v-card-title
        class="mb-16 text-h5 d-flex justify-center font-weight-black"
      >
        {{ body.title }}
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        <icon-success class="icon--success"></icon-success>
      </v-card-text>
      <v-card-text class="mb-16">
        <p class="black--text text-center pl-16 pr-16">{{ body.message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-center mt-8 pb-8">
        <v-btn
          class="pr-5 pl-5 ml-5"
          color="primary"
          depressed
          @click="$emit('confirm')"
        >
          FINALIZAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirm",
  components: {
    IconSuccess: () => import("../icons/IconSuccess"),
  },
  props: {
    dialogConfirm: {
      type: Boolean,
      required: true,
    },
    body: {
      type: Object,
      required: true,
    },
  },
};
</script>

    <style lang="scss" scoped>
.icon--success {
  max-width: 8rem;
}
</style>